import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, LinkIcon } from '@chakra-ui/icons'
import {
  Badge,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  IconButton,
  MenuList,
  MenuItem,
  MenuButton,
  Menu,
  Icon,
  Text,
  Tooltip,
  useDisclosure,
  HStack,
  useMediaQuery,
  Tag,
  VStack,
  chakra,
  Box,
  shouldForwardProp,
} from '@chakra-ui/react'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore'
import React, { useState } from 'react'
import { AiFillCopy, AiFillDelete, AiFillEdit, AiOutlineGlobal } from 'react-icons/ai'
// import { GrOptimize } from 'react-icons/gr'
import { SiGoogleoptimize } from 'react-icons/si'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { db } from '../firebase/config'
import { useCustomToast } from '../hooks/customToast'
import { DeletePrompt, getTotalCampaigns, DuplicatePrompt } from '../utils'
import CustomNameModal from '../pages/Campaigns/components/CustomNameModal'
import { useRecoilState } from 'recoil'
import { campaignDataAtom } from '../recoil/atoms/campaignData'
import { MdWidgets, MdCircle, MdOutlineRvHookup } from 'react-icons/md'

import { QrCodeModal } from './QRcodeModal'
import isValidHTMLProp from '@emotion/is-prop-valid'
import InvitesDataModal from '../pages/Campaigns/components/InvitesDataModal'
import { IoMdCloudUpload } from 'react-icons/io'

const ChakraBoxWrapper = chakra(Box, {
  shouldForwardProp: (prop) => {
    const isValidProp = isValidHTMLProp(prop) || shouldForwardProp(prop)
    if (isValidProp) return true
    return ['isSm'].includes(prop)
  },
  baseStyle: {
    // border: '1px solid',
    // borderColor: 'gray.300',
    borderRadius: '16px',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;',
    padding: '-1px',
    // position: 'relative',
    zIndex: 10,
    overflow: 'hidden',
    // overflow: 'hidden',
  },
})

const ChakraTableContainer = chakra(TableContainer, {
  shouldForwardProp: (prop) => {
    const isValidProp = isValidHTMLProp(prop) || shouldForwardProp(prop)
    if (isValidProp) return true
    return ['isSm'].includes(prop)
  },
  baseStyle: {
    border: '1px solid',
    borderColor: 'gray.300',
    borderRadius: '16px',
    // boxShadow:
    //   'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;',
    // zIndex: 10,
    // position: 'relative',
    overflow: 'auto hidden',
    '& thead': {
      // bg: 'brand.2'
    },
    '& thead > tr > th': {
      fontSize: ['12px', '14px'],
      paddingBlock: ['2', '3'],
      paddingInline: ['4px', '7px', '10px'],
      '&:first-of-type': {
        borderTopLeftRadius: '16px',
      },
      '&:last-of-type': {
        borderTopRightRadius: '16px',
      },
    },
    '& tbody > tr > td': {
      fontSize: ['12px', '14px', '16px'],
      paddingBlock: ['2', '3'],
      paddingInline: ['4px', '7px', '10px'],
    },
    '& tbody > tr:last-of-type > td': {
      // bg: 'gray.500',
      '&:first-of-type': {
        borderBottomLeftRadius: '16px',
      },
      '&:last-of-type': {
        borderBottomRightRadius: '16px',
      },
    },
  },
})

function DataTable({
  variant,
  sx,
  theadSx,
  data,
  dataHead,
  user,
  getCampaigns,
  funcIfCampaignNotExist,
}) {
  const [isLessThan1500] = useMediaQuery('(max-width: 1500px)')

  const [loading, setLoading] = useState(false)
  const [perPage, setPerPage] = useState(10)
  const pagesOption = [10, 20, 50, 100]

  const { addToast } = useCustomToast()
  const navigate = useNavigate()
  const { isOpen: openDlt, onOpen, onClose: closeDlt } = useDisclosure()

  const {
    isOpen: isOpenCustomName,
    onOpen: openCustomName,
    onClose: closeCustomName,
  } = useDisclosure()
  const {
    isOpen: isOpenInvitesData,
    onOpen: openInvitesData,
    onClose: closeInvitesData,
  } = useDisclosure()

  const {
    isOpen: isOpenDuplicate,
    onOpen: openDuplicate,
    onClose: closeDuplicate,
  } = useDisclosure()

  const [campaignId, setCampaignId] = useState('')
  const [campaign, setCampaign] = useState(null)
  const [vanityObj, setVanityObj] = useState(null)

  const [cId, setcId] = useRecoilState(campaignDataAtom)

  const allStatus = [
    { name: 'active', value: true },
    { name: 'off', value: false },
  ]

  let errorToast = (error) =>
    addToast({
      title: 'Error',
      description: error.message,
      status: 'error',
      variant: 'left-accent',
    })

  const updateStatus = async (id, status) => {
    try {
      const docRef = doc(db, 'campaigns', id)
      await updateDoc(docRef, { active: status })
      addToast({
        title: 'Campaign!',
        description: 'Campaign status updated',
        status: 'success',
        variant: 'left-accent',
      })
    } catch (err) {
      errorToast(err)
    }
  }

  const deleteCampaign = async (id) => {
    try {
      const id = campaignId
      setLoading(true)
      const docRef = doc(db, 'campaigns', id)
      await deleteDoc(docRef)
      addToast({
        title: 'Campaign!',
        description: 'Campaign deleted',
        status: 'warning',
        variant: 'left-accent',
      })

      const collRef = collection(db, 'campaigns')
      const getCampaigns = await getDocs(collRef)
      getCampaigns.docs.forEach(async (campaign, idx) => {
        const docRef = doc(db, 'campaigns', campaign.id)
        await updateDoc(docRef, {
          docNo: idx + 1,
        })
      })
      // this function needs to be changed soon as it's not good practice

      handleClose()
      setLoading(false)
    } catch (err) {
      errorToast(err)
      setLoading(false)
    }
  }

  const copyCampaign = async (id) => {
    try {
      const docRef = doc(db, 'campaigns', id)
      let data = (await getDoc(docRef)).data()
      let {
        campaignDetails: { internalCampaignName },
      } = data
      getTotalCampaigns
        .then(async (totalCampaigns) => {
          let finalData = {
            ...data,
            campaignDetails: {
              ...data.campaignDetails,
              internalCampaignName: 'Copy:' + internalCampaignName,
            },
            visits: 0,
            createdAt: serverTimestamp(),
            docNo: totalCampaigns + 1,
            userId: user.uid,
          }
          const collRef = collection(db, 'campaigns')
          await addDoc(collRef, finalData)
          addToast({
            title: 'Campaign!',
            description: 'Campaign cloned',
            status: 'success',
            variant: 'left-accent',
          })
          closeDuplicate()
        })
        .catch((err) => {
          throw err
        })
    } catch (err) {
      errorToast(err)
    }
  }

  const handleOpen = (id) => {
    setCampaignId(id)
    onOpen()
  }

  const handleDuplicateOpen = (id) => {
    setCampaignId(id)
    openDuplicate()
  }

  const handleClose = (_) => {
    setCampaignId('')
    closeDlt()
  }

  const handleOpenCustomName = (campaignId, vanity) => {
    setCampaignId(campaignId)
    setVanityObj(vanity)
    openCustomName()
    // console.log({ campaignId, vanity })
  }
  const handleCloseCustomName = (success = false) => {
    setCampaignId('')
    setVanityObj(null)
    closeCustomName()
    success && getCampaigns()
  }

  const seoOpen = (campaignId) => {
    setcId(campaignId)
    navigate('/dashboard/seo')
  }

  const customRequestOpen = (campaignId) => {
    setcId(campaignId)
    navigate('/dashboard/request-message')
  }

  const handleOpenInvites = (obj) => {
    setCampaign(obj)
    openInvitesData()
  }

  const handleCloseInvites = () => {
    setCampaign(null)
    closeInvitesData()
  }

  const handleDuplicate = (v) => {
    copyCampaign(campaignId)
  }
  const handleCloseDuplicate = () => {
    setCampaign(null)
  }

  return (
    <Box>
      <Text
        display={['block', 'none']}
        ml={1}
        mb={3}
        color='gray.500'
        fontSize='sm'
        fontWeight='bold'
        fontStyle='italic'
      >
        **Scroll the table to see more data**
      </Text>
      <ChakraBoxWrapper>
        <ChakraTableContainer {...sx}>
          <DeletePrompt
            isOpen={openDlt}
            onClose={handleClose}
            accept={deleteCampaign}
            loading={loading}
          />
          <DuplicatePrompt
            isOpen={isOpenDuplicate}
            onClose={closeDuplicate}
            accept={handleDuplicate}
            loading={loading} />
          <CustomNameModal
            isOpen={isOpenCustomName}
            onClose={handleCloseCustomName}
            campaignId={campaignId}
            vanityObj={vanityObj}
            getCampaigns={getCampaigns}
          />
          <InvitesDataModal
            isOpen={isOpenInvitesData}
            onClose={handleCloseInvites}
            campaign={campaign}
            getCampaigns={getCampaigns}
          />
          <Table variant={variant}>
            {/* <TableCaption>caption</TableCaption> */}
            <Thead {...theadSx}>
              <Tr>
                {dataHead.map((v, i) => (
                  <Th
                    key={i}
                    fontSize={'13.5px'}
                    color='brand.1'
                    paddingInline='7px'
                    sx={{
                      textAlign: (i === 1 || i === 2) && 'end',
                      ...(i + 1 === dataHead.length && {
                        bg: 'gray.50',
                        width: ['90px', '110px'],
                        borderTopRightRadius: '18px',
                        // borderEnd: theme => "1px solid " + theme.colors.gray["300"],
                        position: isLessThan1500 ? 'absolute' : 'initial',
                        right: ['4%', '2.2%', '1.5%'],
                        // right: '0%'
                      }),
                      ...(i + 1 === dataHead.length - 1 && {
                        paddingRight: isLessThan1500
                          ? ['90px !important', '110px !important']
                          : '0px',
                        paddingLeft: '30px !important',
                        textAlign: 'justify',
                      }),
                    }}
                  >
                    {v}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {!data?.length > 0 && (
                <Tr>
                  <Td colSpan={'7'} textAlign={'center'}>
                    <VStack
                      p={{ base: 4, sm: 4, md: 6 }}
                      width='full'
                      alignItems={'center'}
                      textAlign={'center'}
                      spacing={'8'}
                    >
                      <Text fontWeight={'bold'} fontSize={['xl', '2xl', '3xl']} color={'brand.1'}>
                        You have no campaigns
                      </Text>
                      <Button
                        mt={'3'}
                        cursor='pointer'
                        variant={'solid'}
                        as={'a'}
                        size={['sm', 'md']}
                        onClick={funcIfCampaignNotExist}
                      >
                        Create One Now!
                      </Button>
                    </VStack>
                  </Td>
                </Tr>
              )}
              {data.map((v, i) => (
                <Tr key={i}>
                  <Td textTransform={'capitalize'} paddingLeft={['15px !important']}>
                    <Menu>
                      <Tooltip
                        color='white'
                        bg={v.status ? 'green.500' : 'yellow.400'}
                        px={2}
                        rounded='md'
                        hasArrow
                        placement='top'
                        label={`Change Status`}
                      >
                        <MenuButton
                          as={chakra.span}
                          size='sm'
                          bg='none'
                          color={v.status ? 'teal.50' : 'teal.50'}
                          borderRadius={'md'}
                          //bg={v.status ? 'teal.50' : 'yellow'}
                          variant={'subtle'}
                        >
                          <Icon
                            mr='1'
                            as={MdCircle}
                            fontSize={15}
                            color={v.status ? 'green.300' : 'yellow'}
                          />
                        </MenuButton>
                      </Tooltip>
                      <MenuList minW={'16'}>
                        {allStatus.map((status, i) => (
                          <MenuItem
                            key={i}
                            mb={i === 0 ? 2 : 0}
                            onClick={() => updateStatus(v.id, status.value)}
                            bg={status.value === v.status ? 'teal.50' : 'white'}
                            _hover={{
                              bg: status.value === v.status ? 'teal.50' : 'white',
                            }}
                          >
                            {status.name}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>

                    {v.name}

                    {v.isTeam && (
                      <Tag colorScheme='linkedin' size='sm' ml='2'>
                        Team
                      </Tag>
                    )}
                  </Td>
                  <Td textAlign={'center'}>{v?.visits}</Td>
                  <Tooltip label={'Invites Data'} hasArrow background='teal.400'>
                    <Td
                      textAlign={'center'}
                      cursor={'pointer'}
                      onClick={() => handleOpenInvites(v)}
                    >
                      {v.sentRequests}
                    </Td>
                  </Tooltip>
                  <Td textAlign={'center'}>{v.reviews}</Td>
                  <Td textAlign={'center'}>{Number(v?.avgRating || 0).toFixed(2)}</Td>
                  {/* <Td textAlign={'center'}>
                <Menu>
                  <MenuButton
                    minW={'82px'}
                    as={Badge}
                    colorScheme={v.status ? 'green' : 'yellow'}
                    borderRadius={'md'}
                    px={'3'}
                    variant={'subtle'}
                  >
                    {v.status ? 'active' : 'off'} <ChevronDownIcon />
                  </MenuButton>
                  <MenuList minW={'16'}>
                    {allStatus.map((status, i) => (
                      <MenuItem
                        key={i}
                        mb={i === 0 ? 2 : 0}
                        onClick={() => updateStatus(v.id, status.value)}
                        bg={status.value === v.status ? 'teal.50' : 'white'}
                        _hover={{
                          bg: status.value === v.status ? 'teal.50' : 'white',
                        }}
                      >
                        {status.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Td> */}
                  <Td textAlign={'center'}>
                    <HStack spacing='2'>
                      <Tooltip
                        color='white'
                        bg='teal.400'
                        px={2}
                        rounded='md'
                        hasArrow
                        placement='bottom'
                        label={'Click to Copy'}
                      >
                        <Button
                          variant={'outline'}
                          leftIcon={<LinkIcon />}
                          size={['xs', 'sm']}
                          onClick={(_) => {
                            navigator.clipboard.writeText(
                              `${window.location.origin}/${v.collectingPage}`
                            )
                            addToast({
                              id: 'review',
                              title: 'Copied!',
                              description: 'Review page url copied',
                              status: 'success',
                              variant: 'left-accent',
                            })
                          }}
                        >
                          URL
                        </Button>
                      </Tooltip>

                      <QrCodeModal
                        title={`Request ${v.name}  Review`}
                        pageUrl={`${window.location.origin}/${v.collectingPage}`}
                      />
                    </HStack>
                  </Td>
                  <Td
                    textAlign={'center'}
                    sx={{
                      paddingRight: isLessThan1500
                        ? ['90px !important', '110px !important']
                        : '0px',
                    }}
                  >
                    <HStack spacing='2'>
                      <Tooltip
                        color='white'
                        bg='teal.400'
                        px={2}
                        rounded='md'
                        hasArrow
                        placement='bottom'
                        label={'Click to Copy'}
                      >
                        <Button
                          variant={'outline'}
                          leftIcon={<LinkIcon />}
                          size={['xs', 'sm']}
                          onClick={(_) => {
                            navigator.clipboard.writeText(`${window.location.origin}/${v.wallPage}`)
                            addToast({
                              id: 'wall',
                              title: 'Copied!',
                              description: 'Review wall url copied',
                              status: 'success',
                              variant: 'left-accent',
                            })
                          }}
                          disabled={!v?.status}
                        >
                          URL
                        </Button>
                      </Tooltip>
                      {/* <QRCode size={30} value={`${window.location.origin}/${v.wallPage}`} /> */}

                      <QrCodeModal
                        title={`${v.name}  Wall`}
                        pageUrl={`${window.location.origin}/${v.wallPage}`}
                      />

                      <Tooltip
                        color='white'
                        bg='teal.400'
                        px={2}
                        rounded='md'
                        hasArrow
                        placement='bottom'
                        label={'My Review Page'}
                      >
                        <IconButton
                          color='teal.500'
                          size={['xs', 'sm']}
                          variant='link'
                          onClick={() => window.open(`${window.location.origin}/${v.wallPage}`)}
                        >
                          <AiOutlineGlobal fontSize={25} />
                        </IconButton>
                      </Tooltip>
                    </HStack>
                  </Td>
                  <Td
                    textAlign={'center'}
                    sx={{
                      position: isLessThan1500 ? 'absolute' : 'initial',
                      right: ['4%', '2.2%', '1.5%'],
                      // right: '-0.50%',
                      bg: 'white',
                      width: ['90px', '110px'],
                      height: ['42px', '56px'],
                      // paddingBottom: '8px',
                      // borderRight: theme => "1px solid " + theme.colors.gray["300"]
                    }}
                  >
                    <Menu closeOnSelect={false}>
                      <Tooltip
                        color='white'
                        bg='teal.400'
                        px={2}
                        rounded='md'
                        hasArrow
                        placement='bottom'
                        label={'Additional Options'}
                      >
                        <MenuButton
                          as={IconButton}
                          icon={<BsThreeDotsVertical />}
                          variant={'ghost'}
                          height={'max-content'}
                        />
                      </Tooltip>

                      <MenuList sx={{ overflowY: 'auto', maxHeight: '22rem' }}>
                        <MenuItem onClick={(_) => navigate('/dashboard/edit-campaign/' + v.id)}>
                          <Icon as={AiFillEdit} color={'brand.1'} mr={'2'} /> Edit
                        </MenuItem>
                        <MenuItem onClick={() => customRequestOpen(v.id)}>
                          <Icon as={SiGoogleoptimize} color={'teal.500'} mr={'2'} /> Custom Review
                          Message
                        </MenuItem>
                        <MenuItem onClick={() => navigate('/dashboard/upload-review/' + v.id)}>
                          <Icon as={IoMdCloudUpload} color={'teal.500'} mr={'2'} />
                          Upload Previous Review
                        </MenuItem>
                        <MenuItem onClick={() => handleOpenCustomName(v.id, v.vanity)}>
                          <Icon as={AiFillEdit} color={'brand.1'} mr={'2'} /> Custom URL Name
                        </MenuItem>
                        <MenuItem onClick={() => seoOpen(v.id)}>
                          <Icon as={SiGoogleoptimize} color={'teal.500'} mr={'2'} /> Campaign SEO
                        </MenuItem>
                        <MenuItem 
                         onClick={() => navigate('/dashboard/socials/' + v.id, { state: v } )}
                        >
                          <Icon as={SiGoogleoptimize} color={'teal.500'} mr={'2'} /> Social Media
                        </MenuItem>
                        <MenuItem
                          onClick={() => navigate('/dashboard/widget/' + v.id, { state: v })}
                        >
                          <Icon as={MdWidgets} color={'brand.1'} mr={'2'} /> Embed Widgets
                        </MenuItem>

                        <MenuItem onClick={() => navigate(`/dashboard/webhook/${v.id}`, { state: v })}>
                          <Icon as={MdOutlineRvHookup} color={'brand.1'} mr={'2'} /> Webhook
                        </MenuItem>
                        <MenuItem onClick={() => navigate(`/dashboard/request-webhook/${v.id}`, { state: v })}>
                          <Icon as={MdOutlineRvHookup} color={'brand.1'} mr={'2'} />Request Webhook
                        </MenuItem>

                        <MenuItem onClick={() => handleDuplicateOpen(v.id)}>
                          <Icon as={AiFillCopy} color={'brand.1'} mr={'2'} /> Duplicate
                        </MenuItem>

                        <MenuItem>
                          <Menu>
                            <MenuButton
                              // minW={'82px'}
                              as={chakra.span}
                              w='full'
                              color={v.status ? 'green' : 'gray.400'}
                              bg='none'
                              borderRadius={'md'}
                              // px={'3'}
                              variant={'subtle'}
                              justifySelf={'start'}
                            >
                              <HStack justify={'space-between'}>
                                {' '}
                                <HStack>
                                  {' '}
                                  <Icon
                                    as={MdCircle}
                                    fontSize={15}
                                    color={v.status ? 'green.300' : 'gray.400'}
                                  />
                                  <Text> {v.status ? 'active' : 'off'} </Text>
                                </HStack>
                                <ChevronDownIcon />
                              </HStack>
                            </MenuButton>
                            <MenuList>
                              {allStatus.map((status, i) => (
                                <MenuItem
                                  key={i}
                                  mb={i === 0 ? 2 : 0}
                                  onClick={() => updateStatus(v.id, status.value)}
                                  bg={status.value === v.status ? 'teal.50' : 'white'}
                                  _hover={{
                                    bg: status.value === v.status ? 'teal.50' : 'white',
                                  }}
                                >
                                  {status.name}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </Menu>
                        </MenuItem>
                        <MenuItem onClick={() => handleOpen(v.id)}>
                          <Icon as={AiFillDelete} color={'red'} mr={'2'} /> Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
            <Tfoot borderTop={'1px solid'}>
              {data.length < 10 ? null : (
                <Tr>
                  <Th colSpan={'6'} textAlign={'right'} textTransform={'full-size-kana'}>
                    Rows per page:
                    <Menu>
                      <MenuButton
                        onChange={(e) => console.log(e.target.value)}
                        rightIcon={<ChevronDownIcon />}
                        variant={'outlined'}
                        fontSize={'sm'}
                      >
                        {perPage}
                      </MenuButton>
                      <MenuList minW={'16'}>
                        {pagesOption
                          .filter((v) => v !== perPage)
                          .map((v, i) => (
                            <MenuItem
                              key={i}
                              fontSize={13}
                              fontWeight={'semibold'}
                              onClick={() => setPerPage(v)}
                            >
                              {v}
                            </MenuItem>
                          ))}
                      </MenuList>
                    </Menu>
                    <Text display={'inline'}>
                      1-{data.length} of {data.length}
                    </Text>
                  </Th>
                  <Th maxW={'24'}>
                    <IconButton icon={<ChevronLeftIcon />} variant={'ghost'} disabled />
                    <IconButton icon={<ChevronRightIcon />} variant={'ghost'} disabled />
                  </Th>
                </Tr>
              )}
            </Tfoot>
          </Table>
        </ChakraTableContainer>
      </ChakraBoxWrapper>
    </Box>
  )
}

export default DataTable
