import React from 'react'
import {
    Flex,
    Heading,
    HStack,
    Image,
    Stack,
    Text,
    chakra,
    Box,
    useBreakpointValue,
    Hide,
    Skeleton,
    SkeletonCircle,
    SkeletonText,
    Button,
    VStack,
    useColorModeValue,
    Img
} from '@chakra-ui/react'
// import { getNameInitials } from '../../../utils'
//import { ContactModal } from './ContactModal'
import { ProfileModal } from '../ProfileModal/ProfileModal'
import Rating from '../../../../components/Rating'
import { ContactModal } from '../ContactModal/ContactModal'
import { handleOpenLink } from '../../../../utils'
import { useParams } from 'react-router-dom'


const MedicalTheme = ({ avgRating, loading, campaignData, disableIcon = false }) => {
    let { campaignId } = useParams();
    const { campaignDetails } = campaignData

    return (
        <>


            <Box width="full">
                {/* <Container maxW="container.xl"> */}
                <Stack
                    spacing={0}
                    direction={["column", "column", "row"]}
                    width="full"
                // minHeight="100vh"
                >
                    <Flex
                        pos="relative"
                        bg="pink.50"
                        flex="1"
                        // height="full"
                        minHeight={[150, 250, 450, "unset"]}
                        sx={{ "& > div": { width: "full" } }}
                    >
                        <Image
                            layout="fill"
                            objectFit="cover"
                            objectPosition="50% 25%"
                            src={campaignDetails?.medicalImage}
                            alt=""
                        />
                    </Flex>

                    <Flex bg={'#545454'} flex="1">
                        <Flex alignSelf="center" py={[10, 10, 0, 28]} px={[5, 5, 10, 20]}>
                            <VStack
                                width="full"
                                align={["center", "center", "start"]}
                                spacing={4}
                            >
                                <Box pr='4'>
                                    <Image
                                        src={campaignDetails?.campaignLogo}
                                        alt=''
                                        maxW={'180px'}
                                        maxH='100px'
                                        objectFit='contain'
                                        rounded="md"
                                    />
                                </Box>
                                <Flex justify={["center", "center", "start"]} flexWrap="wrap">
                                    <Heading fontSize={{ base: '4xl', md: '5xl', lg: '6xl' }}>
                                        <Text
                                            // fontSize='4xl'
                                            color='#F2F2F2'
                                            fontWeight={600}
                                            fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                                            lineHeight={'110%'}>

                                            {campaignDetails?.publicCampaignName ||
                                                campaignDetails?.testimonialCampaignName ||
                                                '-'}
                                        </Text>
                                        <br />{' '}
                                        <HStack pt='4' direction='row' align='center' spacing={'10px'}>
                                            <Rating
                                                total={5}
                                                value={Math.round(avgRating)}
                                                size={['19px']}
                                                isStatic={true}
                                                showNumber={false}
                                            />
                                            <Text fontSize='16px' color='#6BE0E8' pb={1}>
                                                {!!campaignData?.reviews?.length ? campaignData?.totalTestimonials : 0} Review{(campaignData?.totalTestimonials === 0 || campaignData?.totalTestimonials > 1) && 's'}
                                            </Text>
                                        </HStack>
                                        <Text fontSize='16px' color='#6BE0E8'>
                                            {/* <chakra.span color='gray.500' fontWeight='medium'>
                                        Average:
                                    </chakra.span>{' '} */}
                                            {Number(avgRating).toFixed(
                                                avgRating ? 2 : 0
                                            )}{' '}
                                            out of 5 Stars
                                        </Text>
                                    </Heading>
                                </Flex>
                                <SkeletonText startColor='gray.100' endColor='#C8C8C8' fadeDuration={1} isLoaded={!loading}>
                                    <Text fontSize={{ base: 'md', lg: 'lg' }} color={'gray.200'}>

                                        {campaignDetails?.campaignDescription || '-'}

                                    </Text>
                                </SkeletonText>
                                {/* </Skeleton> */}
                                <Skeleton startColor='gray.100' endColor='gray.300' fadeDuration={1} isLoaded={!loading}>
                                    <HStack flexWrap="wrap" spacing={0} gap={[2]}>
                                        {!campaignData?.isExpressSetup &&
                                            <ProfileModal campaignData={campaignData} disableIcon={disableIcon} />
                                        }
                                        <ContactModal campaignData={campaignData} campaignEmail={campaignData?.campaignDetails?.email} disableIcon={disableIcon} />
                                        {!campaignData?.isExpressSetup &&
                                            <Text
                                                pl='2'
                                                fontSize='16px'
                                                // as={'a'}
                                                color='#F7F0F0'
                                                fontWeight='semibold'
                                                // align='right'
                                                // href={
                                                //   campaignDetails?.reviewWallUrl || '#'
                                                // }
                                                // target='_blank'
                                                cursor={'pointer'}
                                                onClick={() => !disableIcon && handleOpenLink(campaignDetails?.reviewWallUrl || '#')}
                                            >
                                                {campaignDetails?.reviewWallUrl}
                                            </Text>
                                        }
                                    </HStack>
                                    <Button
                                        colorScheme='' color='#6BE0E8' mt='10' size='md'
                                        onClick={() => !disableIcon && handleOpenLink(`${window.location.origin}/review/${campaignId || campaignData?.campaignId}` || '#')}
                                    >
                                        Leave  {campaignDetails?.publicCampaignName || campaignDetails?.testimonialCampaignName || '-'} a Review!
                                    </Button>
                                </Skeleton>

                            </VStack>
                        </Flex>
                    </Flex>
                </Stack>
                {/* </Container> */}
            </Box>

        </>
    )
}

export default MedicalTheme