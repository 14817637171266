import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  VStack,
  Icon,
  Avatar,
  useTheme,
  Link,
} from '@chakra-ui/react';
import { FaPlay, FaStar } from 'react-icons/fa';

// Array of video review objects
const VIDEO_REVIEWS = [
  {
    id: 1,
    videoUrl: "https://res.cloudinary.com/codeshock/video/upload/s--lawFEfJA--/eo_18.4,q_auto:good,so_0/v1733526544/sendmea/reviews/VoTYmY69iKh5yABo4Hs7.mp4",
    thumbnailUrl: "https://res.cloudinary.com/codeshock/video/upload/v1733526544/sendmea/reviews/VoTYmY69iKh5yABo4Hs7.jpg",
    reviewerName: "Maureen M",
    campaignName: "Ai for Business",
    reviewText: `"Ai for Business Stellar Three Days! 🙌"`,
    reviewText2:`See more reviews about Ai for Business...`,
    reviewUrl: "https://sendmea.io/wall/ai4b",
  },
  {
    id: 2,
    videoUrl: "https://res.cloudinary.com/codeshock/video/upload/s--PA7owOUN--/eo_15,q_auto:good,so_0/v1728925813/sendmea/reviews/BFTXw3vJGovAYwD7DSmo.mp4",
    thumbnailUrl: "https://res.cloudinary.com/codeshock/video/upload/v1728925813/sendmea/reviews/BFTXw3vJGovAYwD7DSmo.jpg",
    reviewerName: "DaSean D",
    campaignName: "ezREI",
    reviewText: `"Huge thank you to the ezREI team!"`,
    reviewText2:`Watch what many more say about ezREI...`,
    reviewUrl: "https://sendmea.io/wall/ezreiclosings",
  },
  {
    id: 3,
    videoUrl: "https://res.cloudinary.com/codeshock/video/upload/s---9RSZ_Q5--/eo_24.5,q_auto:good,so_0/v1729692299/sendmea/reviews/gXlNDALkQ5Cocm73YjF7.mp4",
    thumbnailUrl: "https://res.cloudinary.com/codeshock/video/upload/v1729692299/sendmea/reviews/gXlNDALkQ5Cocm73YjF7.jpg",
    reviewerName: "Alfredo E",
    campaignName: "Sales the Jen Way",
    reviewText: `"We closed 6 figure profit deals after signing up."`,
    reviewText2:`See what happy clients say about Sales the Jen Way...`,
    reviewUrl: "https://sendmea.io/wall/salesthejenway",
  },
  {
    id: 4,
    videoUrl: "https://res.cloudinary.com/codeshock/video/upload/s--NE-3AY4Q--/eo_14,q_auto:good,so_0/v1731086423/sendmea/reviews/VbhpHyLbNKSqPMMXrrgz.mp4",
    thumbnailUrl: "https://res.cloudinary.com/codeshock/video/upload/v1731086423/sendmea/reviews/VbhpHyLbNKSqPMMXrrgz.jpg",
    reviewerName: "Frank S",
    campaignName: "AI Prompting",
    reviewText: `"Bests AI course available!"`,
    reviewText2:`Learn more about success with AI Prompting course...`,
    reviewUrl: "https://sendmea.io/wall/ai-prompting",
  },
  {
    id: 5,
    videoUrl: "https://res.cloudinary.com/codeshock/video/upload/s--kcaqvZLF--/eo_32,q_auto:good,so_0/v1730492700/sendmea/reviews/AVHqlPh7AH7AVBCeYb2T.mp4",
    thumbnailUrl: "https://res.cloudinary.com/codeshock/video/upload/v1730492700/sendmea/reviews/AVHqlPh7AH7AVBCeYb2T.jpg",
    reviewerName: "Lindsay S",
    campaignName: "Martine Richard",
    reviewText: `"Thank you for showing me how to change my life!"`,
    reviewText2:`Watch how Martine Richard changes lives...`,
    reviewUrl: "https://sendmea.io/wall/martine-richard",
  },
];

const VideoOverlay = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    // Randomly select a video review on component mount
    const randomIndex = Math.floor(Math.random() * VIDEO_REVIEWS.length);
    setSelectedReview(VIDEO_REVIEWS[randomIndex]);
  }, []);

  if (!selectedReview) return null;

  return (
    <Box position="relative" maxW="md" mx="auto" width="90%">
      {/* Screen/Monitor Container */}
      <Box
        position="relative"
        bg="gray.100"
        borderRadius="3xl"
        p={4}
        boxShadow="xl"
      >
        {/* Screen Bezel */}
        <Box
          bg="teal.500"
          borderRadius="2xl"
          p={2}
          position="relative"
        >
          {/* Screen Content */}
          <Box
            position="relative"
            paddingTop="177.77%"
            borderRadius="xl"
            overflow="hidden"
            bg="white"
          >
            <Box position="absolute" top={0} left={0} right={0} bottom={0}>
              {!isPlaying ? (
                <Box position="relative" h="100%">
                  <Image
                    src={selectedReview.thumbnailUrl}
                    alt="Video thumbnail"
                    objectFit="cover"
                    w="100%"
                    h="100%"
                  />
                  {/* Play Button Overlay */}
                  <Flex
                    position="absolute"
                    inset={0}
                    alignItems="center"
                    justifyContent="center"
                    bg="blackAlpha.200"
                  >
                    <VStack spacing={3} bg="rgba(0, 0, 0, 0.3)" p={4} borderRadius="md">
                      <Button
                        onClick={() => setIsPlaying(true)}
                        borderRadius="full"
                        size="lg"
                        colorScheme="teal"
                        width="64px"
                        height="64px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Icon as={FaPlay} boxSize={8} fontWeight="bold" />
                      </Button>
                      <Text
                        color="white"
                        fontSize="2xl"
                        fontWeight="bold"
                        textShadow="0px 2px 4px rgba(0,0,0,0.3)"
                      >
                        See Sendmea in Action!
                      </Text>
                    </VStack>
                  </Flex>
                </Box>
              ) : (
                <Box
                  as="video"
                  src={selectedReview.videoUrl}
                  controls
                  autoPlay
                  w="100%"
                  h="100%"
                  objectFit="cover"
                />
              )}
            </Box>
          </Box>
        </Box>

        {/* Reviewer Info Bubble */}
        <Box
          position="absolute"
          top="-16px"
          right="32px"
          bg="white"
          borderRadius="xl"
          boxShadow="md"
          p={3}
          maxW="xs"
        >
          <Flex gap={2}>
            <Avatar size="sm" name={selectedReview.reviewerName} bg="orange.200" />
            <Box>
              <Text fontWeight="medium" fontSize="sm">
                {selectedReview.reviewerName}
              </Text>
              <Text color="teal.500" fontSize="sm" fontWeight="bold" fontStyle="italic">
                {selectedReview.reviewText}
              </Text>
              <Link
                href={selectedReview.reviewUrl}
                isExternal
                color="gray.500"
                fontSize="sm"
                _hover={{ color: 'teal.500', textDecoration: 'underline' }}
              >
                {selectedReview.reviewText2}
              </Link>
            </Box>
          </Flex>
        </Box>

        {/* Star Rating */}
        <Flex
          position="absolute"
          bottom="32px"
          right="32px"
          bg="white"
          borderRadius="lg"
          boxShadow="md"
          p={2}
          gap={1}
        >
          {Array(5).fill('').map((_, i) => (
            <Icon
              key={i}
              as={FaStar}
              color="yellow.400"
              boxSize={4}
            />
          ))}
        </Flex>

        {/* Decorative Curl
        <Box
          position="absolute"
          right="-24px"
          top="32px"
          transform="rotate(45deg)"
        >
          <Icon
            viewBox="0 0 24 24"
            boxSize={12}
            color="teal.500"
            as={props => (
              <svg
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                {...props}
              >
                <path d="M2 12s4-8 10-8 10 8 10 8-4 8-10 8-10-8-10-8z" />
              </svg>
            )}
          />
        </Box> */}
      </Box>
    </Box>
  );
};

export default VideoOverlay;