import {
    Avatar,
    Box,
    Button,
    FormControl,
    HStack,
    Text,
    VStack,
    chakra,
  } from "@chakra-ui/react";
  import { Select, AsyncSelect } from "chakra-react-select";
  import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
  import React, { useEffect, useState } from "react";
  import { db } from "../../../../firebase/config";
  import Rating from "../../../../components/Rating";
  import Webhook from "../../../Webhooks/webhook";
  import RequestWebhook from "../../../Webhooks/RequestWebhook";
  
  const RequestWebhooks = () => {
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [campaignReviews, setCampaignReviews] = useState(null);
  
    const fetchUsers = async (firstName) => {
      try {
        if (firstName.length >= 3) {
          const userQueryRef = query(
            collection(db, "users"),
            where("firstName", ">=", firstName),
            where("firstName", "<=", firstName + "\uf8ff")
          );
          const getUsers = await getDocs(userQueryRef);
          const userData = getUsers.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          // console.log(userData);
          setOptions(userData?.length > 0 ? userData : options);
          return userData?.length > 0 ? userData : options;
        } else {
          setOptions(options);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
  
    const handleInputChange = (firstName) => {
      setInputValue(firstName);
    };
  
    const [loading, setLoading] = useState(false);
    const [campaigns, setCampaigns] = useState(null);
  
    const fetchUserCampaigns = async (uid) => {
      setLoading(true);
      try {
        const collRef = collection(db, "campaigns");
        const queryRef = query(
          collRef,
          where("userId", "==", uid),
          orderBy("createdAt", "desc")
        );
        let data = await getDocs(queryRef);
  
        let getCampaigns = await Promise.all(
          data.docs.map(async (campaignSnap) => {
            const customURlQuery = await getDocs(
              query(collection(db, "campaigns", campaignSnap.id, "vanity"))
            );
            const customName = customURlQuery?.empty
              ? "-"
              : customURlQuery?.docs[0]?.data()?.vanityName;
  
            let avgRating = 0;
            let approvedReviews = 0;
  
            if (
              "avgRating" in campaignSnap.data() &&
              "approvedReviews" in campaignSnap.data()
            ) {
              avgRating = campaignSnap.data()?.avgRating;
              approvedReviews = campaignSnap.data()?.approvedReviews;
              // console.log('from database', { avgRating, approvedReviews })
            } else {
              const queryTestimonial = query(
                collection(db, "reviews"),
                where("campaignId", "==", campaignSnap.id),
                where("approve", "==", true)
              );
              const getReviews = await getDocs(queryTestimonial);
              avgRating = getReviews.docs
                .map((review) => review.data()?.rating)
                .filter((rating) => !!rating);
              avgRating =
                avgRating.length > 0
                  ? avgRating.reduce((sum, rating) => sum + rating, 0) /
                    avgRating.length
                  : 0;
              approvedReviews = getReviews.size;
              // console.log('from mapping', { avgRating, approvedReviews })
            }
  
            return {
              ...campaignSnap.data(),
              id: campaignSnap.id,
              avgRating: avgRating || 0,
              totalReviews: approvedReviews,
              customName,
            };
          })
        );
        setCampaigns(getCampaigns);
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    };
  
    useEffect(() => {
      const handleReviews = async (id) => {
        const ref = collection(db, "reviews");
        const q = query(
          ref,
          where("campaignId", "==", id),
          orderBy("date", "desc")
        );
        const reviewsQ = await getDocs(q);
        const reviews = reviewsQ.docs.map((v) => ({ ...v.data(), id: v.id }));
        setCampaignReviews(reviews);
      };
      if (selectedCampaign?.id) {
        handleReviews(selectedCampaign?.id);
      } else {
        setCampaignReviews(null);
      }
    }, [selectedCampaign]);
  
    const handleReset = () => {
      setCampaigns([]);
      setSelectedCampaign(null);
      setOptions([]);
      setSelectedValue(null);
      setCampaignReviews(null);
    };
  
    return (
      <>
        <HStack w='full' justifyContent='space-between' alignItems='flex-start'>
          <HStack justify='space-between' spacing={3} w='full'>
            <Button size={"sm"} variant={"solid"} onClick={handleReset}>
              Reset
            </Button>
          </HStack>
        </HStack>
        <HStack
          mb={2}
          spacing={0}
          rowGap={6}
          columnGap={2}
          flexDirection={["column", "column", "row", "row"]}
          sx={{
            "& > div ": {
              // minWidth: '350px',
            },
            "& > div > div": {
              "& input": {
                height: "40px",
              },
            },
          }}>
          <FormControl>
            <Text fontWeight={"500"} mb={2}>
              Search User
            </Text>
            <AsyncSelect
              size={["sm", "md"]}
              useBasicStyles
              loadOptions={fetchUsers}
              defaultOptions={options}
              cacheOptions
              onInputChange={handleInputChange}
              // selectedOptionColor="teal"
              value={selectedValue}
              onChange={(value) => {
                // console.log(value)
                setSelectedValue(value);
                setSelectedCampaign(null);
                fetchUserCampaigns(value?.id);
              }}
              inputValue={inputValue}
              formatOptionLabel={({ firstName, lastName, photoURL, email }) => (
                <HStack spacing={2}>
                  <Avatar
                    boxSize={"40px"}
                    src={photoURL}
                    name={firstName + " " + lastName}
                    alt={"name"}
                  />
                  <VStack spacing={-1} alignItems={"flex-start"}>
                    <Text fontSize={"md"}>
                      {firstName} {lastName}
                    </Text>
                    <Text fontSize={"sm"} color={"gray.400"}>
                      {email}
                    </Text>
                  </VStack>
                </HStack>
              )}
              getOptionValue={(option) => option?.id}
              placeholder='Search...'
              components={{
                Option: ({
                  innerProps,
                  innerRef,
                  data: { firstName, lastName, photoURL, email },
                  isSelected,
                }) => (
                  <HStack
                    spacing={2}
                    ref={innerRef}
                    {...innerProps}
                    p={2}
                    bg={isSelected ? "teal.400" : "transparent"}
                    _hover={{
                      bg: isSelected ? "teal.400" : "teal.100",
                    }}>
                    <Avatar
                      size={"md"}
                      src={photoURL}
                      name={firstName + " " + lastName}
                      alt={firstName + " " + lastName}
                    />
                    <VStack spacing={0} alignItems={"flex-start"}>
                      <Text fontSize={"md"}>
                        {firstName} {lastName}
                      </Text>
                      <Text
                        fontSize={"sm"}
                        color={isSelected ? "gray.700" : "gray.500"}>
                        {email}
                      </Text>
                    </VStack>
                  </HStack>
                ),
              }}
            />
          </FormControl>
          <FormControl>
            <Text fontWeight={"500"} mb={2}>
              Search Campaign
            </Text>
            <Select
              size={["sm", "md"]}
              useBasicStyles
              // loadOptions={fetchUsers}
              options={campaigns || []}
              isLoading={loading}
              isDisabled={!selectedValue}
              // onInputChange={handleInputChange}
              // selectedOptionColor="teal"
              value={selectedCampaign}
              onChange={(value) => {
                // console.log(value)
                setSelectedCampaign(value);
              }}
              // inputValue={inputValue}
              noOptionsMessage={() => <Text>No Campaign</Text>}
              formatOptionLabel={({
                campaignDetails,
                customName,
                campaignLogo,
              }) => (
                <HStack spacing={2}>
                  <Avatar
                    boxSize={"40px"}
                    src={campaignDetails?.campaignLogo}
                    name={campaignDetails?.publicCampaignName}
                    alt={"campaign name"}
                  />
                  <VStack spacing={-1} alignItems={"flex-start"}>
                    <Text fontSize={"md"}>
                      {campaignDetails?.publicCampaignName}
                    </Text>
                    <Text fontSize={"sm"} color={"gray.400"}>
                      {customName}
                    </Text>
                  </VStack>
                </HStack>
              )}
              getOptionValue={(option) => option?.id}
              placeholder='Search...'
              components={{
                Option: ({
                  innerProps,
                  innerRef,
                  data: {
                    campaignDetails: { publicCampaignName, campaignLogo },
                    avgRating,
                    approvedReviews = 0,
                    customName,
                  },
                  isSelected,
                }) => (
                  <HStack
                    spacing={2}
                    ref={innerRef}
                    {...innerProps}
                    p={2}
                    bg={isSelected ? "teal.400" : "transparent"}
                    _hover={{
                      bg: isSelected ? "teal.400" : "teal.100",
                    }}>
                    <Avatar
                      size={"md"}
                      src={campaignLogo}
                      name={publicCampaignName}
                      alt={publicCampaignName}
                    />
                    <VStack spacing={-1} alignItems={"flex-start"}>
                      <Text fontSize={"md"}>
                        {publicCampaignName}{" "}
                        {customName && (
                          <chakra.span fontSize={"14px"} color={"gray.600"}>
                            ({customName})
                          </chakra.span>
                        )}
                      </Text>
                      <HStack
                        direction='row'
                        alignItems='center'
                        spacing={"10px"}>
                        <Rating
                          total={5}
                          value={Math.round(avgRating) || 0}
                          size={["14px"]}
                          isStatic={true}
                          showNumber={false}
                        />
                        <Text
                          fontSize='14px'
                          pt={1}
                          color={isSelected ? "gray.700" : "gray.500"}
                          pb={2}>
                          ({Number(avgRating).toFixed(avgRating ? 2 : 0)})
                        </Text>
                      </HStack>
                      <Text
                        fontSize='14px'
                        color={isSelected ? "brand.2" : "brand.1"}
                        pb={1}
                        fontWeight='bold'>
                        {approvedReviews} Reviews
                      </Text>
                    </VStack>
                  </HStack>
                ),
              }}
            />
          </FormControl>
        </HStack>
  
        {selectedCampaign && <RequestWebhook selectedCampaign={selectedCampaign} />}
      </>
    );
  };
  
  export default RequestWebhooks;
  