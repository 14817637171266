import React, { useState, useEffect, useMemo } from "react";
import {
  Flex,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
  chakra,
  Box,
  useBreakpointValue,
  Hide,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Button,
  Avatar,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
// import { getNameInitials } from '../../../utils'
//import { ContactModal } from './ContactModal'
import { ProfileModal } from "./ProfileModal/ProfileModal";
import Rating from "../../../components/Rating";
import { ContactModal } from "./ContactModal/ContactModal";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase/config";
import { useRecoilState } from "recoil";
import { businessDataAtom } from "../../../recoil/atoms/businessData.js";
import { handleOpenLink } from "../../../utils";
import { BsGlobe2, BsCameraVideoFill } from "react-icons/bs";
import LivelyTheme from "./CampaignBanner/LivelyTheme";
import ForestDarkTheme from "./CampaignBanner/ForestDarkTheme";
import MedicalTheme from "./CampaignBanner/MedicalTheme.js";

export const CampaignBanner = ({ dataLoading, campaignData, wallTheme }) => {
  const [loading, setLoading] = useState(true);
  const [_, setBusinessData] = useRecoilState(businessDataAtom);

  const { campaignDetails, userId } = campaignData;
  // console.log(userId)

  const getBusinessData = async () => {
    if (!userId) {
      setLoading(true);
    } else {
      const ref = await query(
        collection(db, "businesses"),
        where("userId", "==", userId)
      );

      try {
        const docSnap = await getDocs(ref);

        await docSnap.docs.map((doc) => {
          let d = { ...doc.data(), id: doc.id };
          let object = {
            name: d.businessName,
            email: d.businessEmail,
            address: d.businessAddress,
            city: d.city,
            country: d.country,
            phone: d.businessPhone,
            social: d.socialLinks,
            zipCode: d.zipCode,
            description: d.businessDescription,
            type: d.businessType,
          };
          setBusinessData(object);
          // console.log('1', businessData);
        });

        setLoading(false);
      } catch {
        setLoading(false);
      }
    }
  };

  

  useEffect(() => {
    getBusinessData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, loading]);

  const avgRating = useMemo(
    () => (!!campaignData?.reviews?.length ? campaignData?.avgRating || 0 : 0),
    [campaignData]
  );

  return (
    <Flex
      id='campaignData'
      flexDirection='row'
      borderRadius='md'
      //px={[2, 3, 5]}
      // py={6}
      py={{ base: "0", sm: "4" }}
      mx={{ base: "0", md: "16" }}
      // boxShadow='0px 0px 24px rgba(234, 234, 234, 0.7)'
      justify={"center"}>


      {campaignDetails?.wallTheme === "forest_dark" && (
        <ForestDarkTheme
          avgRating={5}
          campaignData={{
            ...campaignData,
            campaignDetails: campaignDetails,
          }}
          loading={dataLoading}
          wallTheme={wallTheme}
        />
      )}
      {campaignDetails?.wallTheme === "medical" && (
        <MedicalTheme
          avgRating={5}
          campaignData={{
            ...campaignData,
            campaignDetails: campaignDetails,
          }}
          loading={dataLoading}
          wallTheme={wallTheme}
        />
      )}

      {(!campaignDetails?.wallTheme && !dataLoading) && (
        <LivelyTheme
          avgRating={5}
          campaignData={{
            ...campaignData,
            campaignDetails: campaignDetails,
          }}
          loading={dataLoading}
        />
      )}

    </Flex>
  );
};
