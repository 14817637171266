import { ArrowForwardIcon } from '@chakra-ui/icons'
import {
  Box,
  // SimpleGrid,
  chakra,
  Image,
  Text,
  Button,
  VStack,
  List,
  // UnorderedList,
  ListItem,
  ListIcon,
  Flex,
} from '@chakra-ui/react'
import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { FaPlay } from 'react-icons/fa'
import { Icon } from '@chakra-ui/react'

function GetStarted() {
  const [isPlaying, setIsPlaying] = useState(false)
  const videoRef = useRef(null)

  const handlePlayButtonClick = () => {
    setIsPlaying(true)
    if (videoRef.current) {
      videoRef.current.play()
    }
  }

  return (
    <chakra.div mt='8' bg={'rgba(180, 224, 226, 0.1)'} py={'12'}
    // id='getStarted'
    >
      <Text
        fontSize={['1.85rem', '2rem']}
        fontWeight={'600'}
        my={'4'}
        textAlign={'center'}
        pt={'6'}
      >
        <chakra.span color={'brand.1'}>Sendmea Video </chakra.span>
        <chakra.span color={'brand.2'}>Review features!</chakra.span>
      </Text>

      <Flex direction={{ base: 'column', sm: 'column', md: 'row' }} py={8} px={['4', '5', '6']}
        alignItems={['center', 'center']}
        justifyContent={['center', 'center']}
        gap={[10, 10, 6, 10]}
      >

        <Box my={'auto'} minWidth={['auto', '400px']} maxW={['400px', '500px', '600px']} position="relative">
          <Box
            as="video"
            ref={videoRef}
            src="https://res.cloudinary.com/codeshock/video/upload/s--V_rHxR2l--/q_auto:eco/v1734918836/sendmea/clips/unlock-the-power-of-sendmea-video-reviews_toxd5r.mp4"
            controls={isPlaying}
            playsInline
            width="100%"
            height="auto"
            borderRadius="md"
            zIndex="1"
          />
          {!isPlaying && (
            <Flex
              position="absolute"
              inset={0}
              alignItems="center"
              justifyContent="center"
              bg="blackAlpha.200"
              zIndex="2"
            >
              <VStack spacing={3} bg="rgba(0, 0, 0, 0.3)" p={4} borderRadius="md">
                <Button
                  onClick={handlePlayButtonClick}
                  borderRadius="full"
                  size="lg"
                  colorScheme="teal"
                  width="64px"
                  height="64px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon as={FaPlay} boxSize={8} fontWeight="bold" />
                </Button>
                          </VStack>
            </Flex>
          )}
        </Box>
        <VStack
          justifyContent='space-between'
          maxW={'550px'}
        >
          <VStack
            spacing={4}
            sx={{
              '& > p': {
                color: 'gray.700',
                fontSize: ['md', 'lg'],
              },
            }}
          >
            <List spacing={8}>
              <ListItem fontSize={'1.2rem'}>
                <ListIcon color='green.500' />
                Video reviews are powerful for helping businesses grow because they provide
                potential customers with a more in-depth understanding of the product or service
                being offered.
              </ListItem>
              <ListItem fontSize={'1.2rem'}>
                <ListIcon color='green.500' />
                Video reviews build trust and credibility with potential customers, as they can see
                and hear from actual customers who have used the product or service.
              </ListItem>
              <ListItem fontSize={'1.2rem'}>
                <ListIcon color='green.500' />
                Videos can be more engaging and memorable for viewers than text-based reviews, which
                can lead to increased conversions and sales.
              </ListItem>
              <ListItem fontSize={'1.2rem'}>
                <ListIcon color='green.500' />
                Videos can be shared easily on social media platforms, which can help increase
                visibility and reach for a business.
              </ListItem>
            </List>
          </VStack>
        </VStack>
      </Flex>
      <chakra.div m={'6'} textAlign={'center'}>
        <Button w={'36'} as={Link} to={'/register'} variant={'solid'} mb={'6'}>
          Sign up <ArrowForwardIcon w={'10'} />
        </Button>
      </chakra.div>
    </chakra.div>
  )
}

export default GetStarted
